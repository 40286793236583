import { useStaticQuery, graphql } from "gatsby";
import Edge from "./edge";
import Vehicle from "./vehicle";

export const Inventory = (): Vehicle[] => {
  const query = useStaticQuery(graphql`
    query {
      vehicles: allInventoryJson {
        edges {
          node {
            id
            brand
            version
            category
            color
            doors
            equipmentlist
            fuel
            gearbox
            hp
            kms
            model
            month
            obs
            images
            price
            seats
            section
            state
            transmission
            warrantymonths
            year
          }
        }
      }
    }
  `);

  const vehicles = (query.vehicles.edges as Edge<Vehicle>[]).map(x => x.node);
  return vehicles;
};
