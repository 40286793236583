import * as React from "react";

export interface Option {
  label: string;
  value: string;
}

interface ISelectProps {
  id: string;
  label?: string;
  selected?: string;
  options?: Option[];
  className?: string;
  disabled?: boolean;
  onChange?: React.ChangeEventHandler<HTMLSelectElement>;
}

const Select: React.FC<ISelectProps> = ({
  id,
  label,
  selected = "",
  options,
  className,
  disabled = false,
  onChange
}) => {
  return (
    <div className={`${className || ""}`}>
      <label
        className="block text-gray-700 text-sm font-bold mb-2"
        htmlFor={id}
      >
        {label}
      </label>
      <select
        disabled={disabled}
        className={`disabled:opacity-75 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
        id={id}
        onChange={onChange}
        value={selected}
      >
        {options?.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Select;
