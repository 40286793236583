export type QueryString = {
  [Key: string]: string | string[];
};

export function parseQueryString(queryString: string): QueryString {
  return queryString
    .replace("?", "")
    .split("&")
    .filter(value => value.length > 0)
    .map(pair => {
      const [key, value] = pair.split("=");
      if (value.includes(",")) {
        return { [key]: value.split(",") } as QueryString;
      } else {
        return { [key]: value } as QueryString;
      }
    })
    .reduce((previous, current) => Object.assign(previous, { ...current }), {});
}

export function createQueryString(queryString: QueryString): string {
  const pairs = [];
  for (let [key, value] of Object.entries(queryString)) {
    if (Array.isArray(value)) {
      if (value[0].length === 0 && value[1].length === 0) {
        continue;
      }

      pairs.push(`${key}=${value[0]},${value[1]}`);
    } else {
      if (value.length === 0) {
        continue;
      }

      pairs.push(`${key}=${value}`);
    }
  }
  return `?${pairs.sort().join("&")}`;
}
